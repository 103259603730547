import { Component, Input, Output, EventEmitter } from '@angular/core';

// TODO DELETE

@Component({
    selector: 'cmm-raised-button',
    templateUrl: './raised-button.component.html',
    styleUrls: ['./raised-button.component.scss']
})
export class RaisedButtonComponent {
    @Input() text: string;
    @Input() color: string;
    @Input() disabled: boolean;

    constructor() {
        this.disabled = false;
    }

    onClick(event: EventEmitter<any>): void {}
}
