import { Moment } from "moment";

export enum DeviceReportType {
    DEAD_BATTERY = 'DEAD_BATTERY',
    DEAD_PI = 'DEAD_PI',
    DEAD_SISTERBOARD = 'DEAD_SISTERBOARD',
    BAD_MODEM = 'BAD_MODEM',
    ONLY_NEEDED_RESET = 'ONLY_NEEDED_RESET',
    OTHER = 'OTHER',
    AUTO = 'AUTO'
}

export interface DeviceReport {
    chain: string,
    storeName: string,
    deviceId: string,
    cameraId: string,
    updatedBy: string,
    date: Moment,
    type: DeviceReportType,
    comment: string,
    critical?: boolean
}

export const DeviceReportTypeValues = {
    [DeviceReportType.DEAD_BATTERY]: 'Dead Battery',
    [DeviceReportType.DEAD_PI]: 'Dead Pi',
    [DeviceReportType.DEAD_SISTERBOARD]: 'Dead Sisterboard',
    [DeviceReportType.BAD_MODEM]: 'Bad Modem',
    [DeviceReportType.ONLY_NEEDED_RESET]: 'Only Needed Reset',
    [DeviceReportType.OTHER]: 'Other',
    [DeviceReportType.AUTO]: 'Auto'
}