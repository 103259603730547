import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialComponentsSharedModule} from 'app/common/components/material/MaterialComponentsSharedModule';
import {RaisedButtonComponent} from './raised-button.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialComponentsSharedModule
    ],
    declarations: [
        RaisedButtonComponent
    ],
    exports: [
        RaisedButtonComponent
    ]
})
export class RaisedButtonModule {
}



